import React from 'react';
import { svgToCssUrl } from 'src/utils/svgToCssUrl';
import styled, { css } from 'styled-components';
import Icon from '../Icon';
import PremiumStar from '../Icon/icons/premium-star.svg';
import { SvgHelperWrapper } from '../Icon/iconHelper';

export const Caret = styled(Icon)(
  ({ theme }) => css`
    pointer-events: none;
    top: 50%;

    & > svg {
      width: ${theme.sz.s3};
    }
  `,
);

export const Title = styled.h1(
  ({ theme }) => css`
    position: relative;
    font-family: ${theme.DEPRECATED_ty.boing};
    font-size: ${theme.DEPRECATED_ty.larger};
    display: inline-block;
    color: ${theme.co.secondary20};
    padding: 0 ${theme.sz.s4} 0 0;
    max-width: 35vw;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;

    @media (min-width: ${theme.sz.md}) {
      font-size: ${theme.DEPRECATED_ty.largerStill};
    }
  `,
);

export const StyledIcon = styled(Icon)<{ $isProfileIcon?: boolean }>(
  ({ theme, $isProfileIcon }) => css`
    svg {
      width: ${theme.sz.s8};
      height: ${$isProfileIcon ? theme.DEPRECATED_ty.largerStill : theme.sz.s8};
      vertical-align: ${$isProfileIcon ? 'inherit' : 'middle'};
      color: ${theme.co.neutral0};
    }
  `,
);

export const HeaderDropdownIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  user-select: none;
`;

const premiumStar = (() => {
  return `${svgToCssUrl(<SvgHelperWrapper Svg={PremiumStar} />)}`;
})();

export const HeaderDropdownButton = styled.button<{
  $isProfileIcon: boolean;
  $isPremium: boolean;
}>(
  ({ theme, $isProfileIcon, $isPremium }) => css`
    background: ${$isProfileIcon ? theme.co.neutral98 : 'none'};
    border: 0;
    padding: ${$isProfileIcon
      ? `${theme.sz.s2} ${theme.sz.s1} ${theme.sz.s1} ${theme.sz.s2}`
      : '0'};
    border-radius: ${$isProfileIcon ? theme.bo.round : 'none'};
    margin-bottom: 0;
    cursor: pointer;

    &:hover,
    &:focus {
      background: ${$isProfileIcon ? theme.co.neutral95 : 'none'};
    }

    ${$isProfileIcon && $isPremium
      ? css`
          &::after {
            content: '';
            background-image: ${premiumStar};
            position: absolute;
            background-repeat: no-repeat;
            height: ${theme.sz.s4};
            width: ${theme.sz.s4};
            bottom: ${theme.sz.s1};
            /*! @noflip */
            right: ${theme.sz.s05};
          }
        `
      : ''}
  `,
);

interface HeaderDropdownIconInnerProps {
  $imageSrc?: string;
}

export const HeaderDropdownIconInner = styled.div<HeaderDropdownIconInnerProps>(
  ({ theme, $imageSrc }) => css`
    background: ${theme.co.secondary70};
    background-image: ${$imageSrc && `url(${$imageSrc})`};
    background-position: center;
    // 110% because category images include a shadow at the bottom. The extra 10% hides it.
    background-size: 110%;
    width: ${theme.sz.s10};
    height: ${theme.sz.s10};
    border-radius: ${theme.bo.round};
  `,
);
