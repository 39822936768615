import React, { FC } from 'react';
import * as S from './styles';

export type StreakType = {
  accomplishedToday: boolean;
  counter: number;
};

export const StreakCounter: FC<StreakType> = ({ counter, accomplishedToday }) => {
  return (
    <S.Container data-testid="streak-info" $disabled={!accomplishedToday}>
      <S.FireIcon name={accomplishedToday ? 'fire' : 'fireDisabled'} />
      <S.Counter>{counter}</S.Counter>
    </S.Container>
  );
};

export default StreakCounter;
