import styled, { css } from 'styled-components';
import Icon from '../Icon';

export const Container = styled.div<{ $disabled: boolean }>(
  ({ theme, $disabled }) => css`
    display: flex;
    flex-direction: row;
    align-items: center;
    color: ${$disabled && theme.co.secondary60};
  `,
);

export const Counter = styled.div(
  ({ theme }) => css`
    ${theme.typography.heading.desktop.xxs};
  `,
);

export const FireIcon = styled(Icon)(
  ({ theme }) => css`
    margin-right: ${theme.sz.s1};
    svg {
      height: ${theme.sz.s6};
      width: ${theme.sz.s6};
    }
  `,
);
