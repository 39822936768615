import styled, { css } from 'styled-components';
import Box from '../../componentsClassic/Box';

export const HeaderWrapper = styled(Box)(
  ({ theme }) => css`
    position: sticky;
    top: 0;
    z-index: ${theme.zi.header};
    padding: ${theme.sz.s4};
    width: 100%;
    display: flex;
    align-items: center;
  `,
);

export const HeaderWrapperInner = styled.nav<{ $minimalHeader?: boolean }>(
  ({ $minimalHeader }) => css`
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex: ${$minimalHeader ? 0 : 1};
    align-items: center;
  `,
);

export const HeaderInner = styled.nav`
  display: flex;
  align-items: baseline;
`;

export const ChildrenWrapper = styled.div<{ $minimalHeader?: boolean }>(
  ({ theme, $minimalHeader }) => css`
    flex: 1;
    margin-right: ${theme.sz.s8};
    display: flex;
    justify-content: ${$minimalHeader ? 'flex-start' : 'flex-end'};
    align-items: center;
    height: ${theme.sz.s10};

    @media (max-width: ${theme.sz.maxMobileBreakpoint}) {
      flex: 1;
      margin-right: ${$minimalHeader ? 0 : theme.sz.s8};
    }
  `,
);

export const HamburgerMenu = styled.div(
  ({ theme }) => css`
    display: block;

    @media (min-width: ${theme.sz.minMobileBreakpoint}) {
      display: none;
    }
  `,
);

export const IndividualLinks = styled.div(
  ({ theme }) => css`
    display: none;

    @media (min-width: ${theme.sz.minMobileBreakpoint}) {
      display: block;
    }
  `,
);

export const HeaderLinks = styled.div`
  display: flex;
  flex-direction: row;
`;

export const BetaTag = styled.a(
  ({ theme }) => css`
    display: block;
    text-decoration: none;
    margin: 0 ${theme.sz.s8} 0;
    white-space: nowrap;
    color: ${theme.co.neutral0};
    font-weight: ${theme.DEPRECATED_ty.bold};

    @media (max-width: ${theme.sz.sm}) {
      display: none;
    }

    &:any-link:hover,
    &:any-link:focus-visible {
      color: ${theme.co.primary60};
    }
  `,
);

export const Link = styled.a<{
  $isActive?: boolean;
  $lightHeader?: boolean;
}>(
  ({ theme, $isActive, $lightHeader }) => css`
    color: ${$lightHeader ? theme.co.neutral0 : theme.co.neutral99};
    font-size: ${theme.sz.s4};
    font-weight: ${theme.DEPRECATED_ty.bold};
    text-decoration: none;
    margin-right: ${theme.sz.s8};
    display: block;
    white-space: nowrap;

    &::after {
      background-color: ${theme.co.primary20};
      content: '';
      display: block;
      visibility: ${$isActive ? 'visible' : 'hidden'};
      height: 0.375rem;
      transform: rotate(-2deg);
    }

    &:hover::after {
      visibility: visible;
    }
  `,
);

export const StreakWrapper = styled.button(
  ({ theme }) => css`
    background: none;
    border: none;
    cursor: pointer;
    margin-right: ${theme.sz.s4};

    @media (max-width: ${theme.sz.minMobileBreakpoint}) {
      margin: 0 ${theme.sz.s2};
    }
  `,
);

export const BookWrapper = styled.a(
  ({ theme }) => css`
    cursor: pointer;
    text-decoration: none;
    color: ${theme.co.neutral0};

    &:hover,
    &:focus {
      color: ${theme.co.neutral40};
    }

    svg {
      height: ${theme.sz.s6};
      width: ${theme.sz.s6};
    }
    margin-right: ${theme.sz.s4};

    @media (max-width: ${theme.sz.minMobileBreakpoint}) {
      margin: 0;
    }
  `,
);
